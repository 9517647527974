<template>
 <div class="splash-el">
  <div class="logo-el">
   <img src="/icons/logo.svg" alt="logo" class="logo-image-el" />
   <v-loader-ring class="mx-auto" :color="'var(--root-green)'"></v-loader-ring>
  </div>
 </div>
</template>
<style scoped>
.splash-el {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 10000;
 background-color: #fff;
 text-align: center;
}

.logo-el {
 position: absolute;
 top: 50%;
 left: 50%;
 width: 30%;
 transform: translate3d(-50%, -50%, 0);
}
.logo-image-el {
 width: 170px;
}
</style>
